<template>
    <v-card flat class="fill-height box">
        <v-app-bar flat class="row header mx-0">
            <img :src="require('../assets/LeonardoIcon.svg')" width="75" />
            <v-divider class="mx-3" inset vertical></v-divider>
            <span class="headline">Rental Shop</span>
        </v-app-bar>
        
        <v-card-text class="row content mx-0">
            <v-row align-content="center" justify="center" no-gutters style="width:100%;">
                <v-col cols="12" md="10" lg="7" xl="6" :class="$vuetify.breakpoint.smAndDown ? 'mb-10' : ''">
                    <v-card-title class="headline pl-0">Checkout</v-card-title>
                    <v-card-subtitle class="pl-0">Set a payment method to complete your order</v-card-subtitle>

                    <div class="subtitle-1 mb-5">Order summary</div>

                    <v-row>
                        <v-col cols="12" md="4" :style="'height: ' + ( $vuetify.breakpoint.name == 'xs' ? '540px' : '100%' )">
                            <package-image :outfit="rentalOufit"></package-image>
                        </v-col>

                        <v-col cols="12" md="8">
                            <div class="subtitle-1">
                                <v-row>
                                    <v-col cols="8">{{ productName }}</v-col>
                                    <v-col class="text-right">
                                        <span style="text-decoration:line-through;" class="caption error--text" v-show="promoCode != null">{{ formatMoney( regularSubtotal ) }} </span>
                                        {{ formatMoney( subtotal ) }}
                                    </v-col>
                                </v-row>
                            </div>
                            <div class="caption" v-if="path == 'rent'">Combination: {{ outfit.name }}</div>

                            <div class="my-5"></div>

                            <div class="subtitle-2">Other charges</div>
                            <v-row no-gutters>
                                <v-col cols="6" class="">Damage waiver:</v-col>
                                <v-col cols="6" class="text-right">{{ toMoney( otherChargesDiscounts ) }}</v-col>

                                <v-col cols="6" class="">Tax (IVU):</v-col>
                                <v-col cols="6" class="text-right">{{ toMoney( tax ) }}</v-col>

                                <v-col cols="6" class="subtitle-1">Total:</v-col>
                                <v-col cols="6" class="subtitle-1 text-right"><strong>{{ toMoney( total ) }}</strong></v-col>
                            </v-row>

                            <div class="subtitle-2 mt-5">Use date</div>
                            <div class="caption">{{ useDate }}</div>

                            <div class="my-5"></div>

                            <v-row no-gutters>
                                <v-col cols="6" md="6">
                                    <div class="subtitle-2">Customer information</div>

                                    <div class="caption">{{ customer.firstName }} {{ customer.lastName }}</div>
                                    <div class="caption">{{ customer.addressLine1 }}</div>
                                    <div class="caption">{{ customer.addressLine2 }}</div>
                                    <div class="caption">{{ customer.addressCity }} {{ customer.addressState }}, {{ customer.addressZipcode }}</div>
                                    <div class="caption">{{ toPhoneNum( customer.phone ) }}</div>
                                    <div class="caption">{{ customer.email }}</div>
                                </v-col>

                                <v-col cols="6" md="6">
                                    <div class="subtitle-2">Measurements</div>
                                    <v-row no-gutters>
                                        <v-col cols="3" class="caption" v-show="heightFeet">Height:</v-col>
                                        <v-col cols="9" class="caption" v-show="heightFeet">{{ heightFeet }}' {{ heightInches }}''</v-col>

                                        <v-col cols="3" class="caption" v-show="weight">Weight:</v-col>
                                        <v-col cols="9" class="caption" v-show="weight">{{ weight }} lbs</v-col>

                                        <v-col cols="3" class="caption">Coat:</v-col>
                                        <v-col cols="9" class="caption">{{ measurements.coatSize }}{{ measurements.coatLength }} | {{ measurements.coatSleeve }}</v-col>

                                        <v-col cols="3" class="caption">Pants:</v-col>
                                        <v-col cols="9" class="caption">{{ measurements.pantWaist }}{{ measurements.pantType }} | {{ measurements.pantOutseam }}</v-col>

                                        <v-col cols="3" class="caption">Shirt:</v-col>
                                        <v-col cols="9" class="caption">{{ measurements.shirtSize }}</v-col>

                                        <v-col cols="3" class="caption">Shoes:</v-col>
                                        <v-col cols="9" class="caption">{{ measurements.shoeSize }}</v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                    <div class="subtitle-1 my-5">Pickup locations</div>

                    <v-radio-group v-model="pickupLocationId" id="pickupLocations">
                        <v-radio v-for="l in storePickupLocations" :label="l.name" :value="l.id" :key="l.id"></v-radio>
                    </v-radio-group>

                    <div class="subtitle-1 my-5">Terms & Conditions</div>

                    <v-checkbox v-model="agreement">
                        <template v-slot:label>
                            <span @click.prevent.stop="contractDialog = true">
                                I have read and agree with the <span style="color:blue;">Customer rental agreement</span>
                            </span>
                        </template>
                    </v-checkbox>

                    <v-btn block depressed x-large color="primary" @click="processOrder" :loading="processing" style="text-transform:none;font-weight:400;">
                        Pay at cashier {{ formatMoney( total ) }} 
                    </v-btn>
                </v-col>
            </v-row>

        </v-card-text>

        <v-dialog v-model="contractDialog" width="900" :fullscreen="$vuetify.breakpoint.smAndDown">
            <v-card>
                <v-card-title>
                    Rental agreement

                    <v-spacer></v-spacer>

                    <v-btn icon @click="contractDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <p style="text-align: justify;">
                        This Rental Agreement is a legal binding agreement between the customer and Leonardo FifthAvenue (LFA) 
                        and establishes the terms and conditions for the rent of suits, tuxedos and related accessories and items through the stores 
                        and/or website at www.L5a.com. By signing and/or approving this order, the customer agrees to this terms and conditions of 
                        use. A non-refundable <b>$10.00 fee</b> is added to the cost of the order as an accidental damage waiver that covers damages that 
                        can be repaired through normal dry cleaning or minor tailoring. This waiver is not applicable to intentional damages, lost 
                        or stolen apparel. The client agrees to release LFA for lost or damaged personal objects left in the returned rental 
                        clothing and will refund (in part or in full) any orders or reimburse the costs of any personal items. The client is 
                        responsible for all lost or stolen rental clothing and must pay the replacement cost. The client assumes responsibility to 
                        return all rented clothing items the following working day after the date of the event. If the client does not return on 
                        time all rented clothing items, the client will pay <b>$25.00</b> for each day of delay in the return of all the products of the order. 
                        All orders not returned for 7 consecutive days, will be considered in delinquency and a charge of $600.00 will be applied and 
                        paid by the customer. Delinquency charge will be due at the moment of its notification to the client. The rental agreement may 
                        be canceled if made on the same day of the transaction before 5:00 p.m. Same day cancellation policy will not apply to “RUSH” 
                        orders to be delivered within 48 hours or less. There will not be cash reimbursement in cancellation, and a store credit will 
                        be applied. Cancellations in rental orders with more than two (2) weeks of anticipation of its use date, will requirea service 
                        charge of <b>$40.00</b> and the difference will be given as a store credit. Cancellations in rental order with less than two (2) weeks 
                        of its use date, will not apply for a store credit, regardless the reason for the cancellation. Postponements due to Covid-19 
                        with less than three (3) weeks of anticipation of use date, require a $40.00 service charge per order. Order changes with less 
                        than 7 calendar days in advance of the use date require a charge of $10.00 per order change. Store credits may be used for a 
                        future reservation within <b>twelve (12) months</b> of your original event date and may apply for rental and off the rack sales. Store 
                        credits do not apply to the Custom made program. Store credits cannot be transferred to other clients. Offer styles will be 
                        honored only during the offer valid period, and in the event that the offer is not available during offer period, items may 
                        be substituted by similar products. <b>Clients are required to try on all the products of the rental order within 24 hours of receipt 
                        of the products and if any replacement is needed, the client must contact the store by phone and coordinate the needed replacement 
                        within this period</b>. LFA is not responsible for problems with orders that are picked up by the client in the same day of the eventor 
                        in replacement requests made more than 24 hours from receipt of order. The client accepts that any legal responsibility of LFA 
                        towards the client will not exceed the total amount paid by the client. I certify to have read, understood and agreed to the 
                        above mentioned terms in this rental agreement.
                    </p>
                    <hr />
                    <br>
                    <p style="text-align: justify;">
                        El contrato de alquiler es un acuerdo entre el cliente y Leonardo 5th Avenue (LFA) que establece
                        los términos y condiciones en la renta de trajes, etiquetas y accesorios en las tiendas y/o en
                        www.L5a.com. Al firmar y/o aprobar esta orden, el cliente acepta sus términos. El pago de <b>$10.00</b>
                        no reembolsable es añadido al costo por concepto de relevo de responsabilidad, mediante el cual
                        LFA cede su derecho a reclamar al cliente por aquellos daños no intencionales casuados a la
                        vestimenta que pudieran ser reparadas mediante sastrería o lavado en seco. Este relevo de
                        responsabilidad no aplica a daño intencional, robo o piezas perdidas. El cliente expresamente
                        releva a LFA de responsabilidad por la pérdida o daños ocasionados a artículos personales
                        dejados en la ropa. El cliente asume completa responsabilidad por los daños o pérdida de
                        artículos alquilados y se compromete a realizar el pago para el reemplazo. El cliente se
                        compromete a devolver los artículos el próximo dia laborable luego de la fecha de uso y autoriza
                        que se le imponga un cargo de <b>$25.00</b> por cada día de atraso. Transcurridos siete (7) días
                        calendario de atraso a partir de la fecha de devolución, será considerada en delincuencia y se
                        aplicará un cargo de $600.00 por concepto de reemplazo de mercancía, el cual el cliente autoriza.
                        Dicha deuda será líquida y exigible al momento de la notificación de la misma al cliente. Alquileres
                        y sus respectivos cobros podría ser anulables (VOID) únicamente el mismo dia de la transacción
                        antes de las 5:00 pm; esto no aplicará a ordenes ”RUSH” con fecha de entrega de 48 horas o
                        menos. <b>En cancelaciones no se hará reembolso en dinero, únicamente se concederá un crédito
                        de tienda</b>. En alquileres cancelados con dos semanas o más de anticipación a la fecha de uso, se
                        retendrá $40.00 por cargo de servicio, y la diferencia se otorgará en un crédito de tienda. En
                        alquileres cancelados con menos de dos semanas de anticipación a la fecha de uso, no se
                        concederá el crédito de tienda, independientemente del motivo de la cancelación. En
                        posposiciones en la fecha del evento por COVID-19, con menos de tres (3) semanas de la fecha
                        de la actividad, LFA autoriza la posposición de fecha en las ordenes, reteniendo un cargo de
                        $40.00 por cada orden pospuesta. Cambios en ordenes realizadas con menos de siete (7) dias
                        calendarios de anticipación a la fecha de uso conlleva un cargo de $10.00. Créditos de tienda
                        tienen una vigencia de <b>doce (12) meses</b> a contar desde la fecha original del evento, aplicables a
                        artículos de alquiler y venta. No aplica al programa de Diseño a la Medida. Créditos de tienda no
                        pueden ser transferidos a otras personas. Estilos en oferta serán honrados mientras duren; de
                        no estar disponibles, serán sustituidos por otros similares de igual o superior calidad. <b>El cliente
                        se compromete a medirse la vestimenta recibida dentro de las siguientes 24 horas desde el
                        recibo de su orden, y de necesitar reemplazo en alguna pieza, comunicarse a la tienda dentro
                        de dicho término para la coordinación del reemplazo</b>. LFA no es reponsable por problemas en
                        órdenes que son recogidas por el cliente el mismo dia del evento o en solicitudes de reemplazo
                        luego de 24 horas de recibida la orden. El cliente acepta que cualquier responsabilidad legal de
                        LFA en cuanto a cualquier asunto relacionada a una orden, la misma estará limitada a un máximo
                        del total de cargos que el cliente haya pagado por el concepto de la orden. Certifico haber leído,
                        entendido y estar de acuerdo con los términos del presente acuerdo de alquiler.
                    </p>
                </v-card-text>
                <v-card-actions>
                    <v-btn block depressed color="primary" @click="agree">
                        I agree
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import { API } from '@/inc/api';
import { mapGetters, mapActions } from 'vuex';
import { _st } from '@/softech';
import PackageImage from '@/components/PackageImage.vue';

export default {
    data: () => ({
        loading         : false,
        loadingLocations: false,
        pickupLocationId: '',
        pickupLocations : [],
        agreement       : false,
        contractDialog  : false,

        processing      : false,
    }),
    computed: {
        ...mapGetters({
            outfit      : 'outfit',
            product     : 'product',
            heightFeet  : 'heightFeet',
            heightInches: 'heightInches',
            weight      : 'weight',
            customer    : 'customer',
            useDate     : 'useDate',
            measurements: 'measurements',
            path        : 'path',
            reservation : 'reservation',
            event       : 'event',
            promoCode   : 'promoCode'
        }),
        storePickupLocations() {
            let s = this.pickupLocations.filter(p => p.isStore === true);
            return s;
        },
        regularSubtotal() {
            if( this.path == 'event' )
                return this.reservation.subtotal;

            // check if combination containes shoes
            let ix = this.outfit.items.findIndex(i => i.categoryID == 'SH' );
            if( ix == -1 )
                return this.product.price;

            return parseFloat( this.product.price ) + 25;
        },
        subtotal() {
            if( this.path == 'event' )
                return this.reservation.subtotal;

            // check if combination containes shoes
            let ix = this.outfit.items.findIndex(i => i.categoryID == 'SH' );
            if( ix == -1 )
                return this.getPackagePrice( this.product.price );

            return parseFloat( this.getPackagePrice( this.product.price ) ) + 25;
        },
        otherChargesDiscounts() {
            if( this.path == 'event' )
                return this.reservation.otherChargesDiscount;

            // damage waiver
            return 10;
        },
        tax() {
            if( this.path == 'event' )
                return this.reservation.tax;

            return ( parseFloat( this.subtotal ) + 10 ) * 0.115;
        },
        total() {
            if( this.path == 'event' )
                return this.reservation.total;

            return parseFloat( this.subtotal ) + 10 + this.tax;
        },
        productName() {
            if( this.path == 'event' ) 
                return `Outfit for event #${this.event.foreignKey}`;

            return this.product.name;
        },
        rentalOufit() {
            if( this.path == 'event' )
                return this.reservation;

            return this.outfit;
        }
    },
    mounted() {
        if( this.path == 'rent' && ( _st.isNUE( this.product ) || _st.isNUE( this.outfit ) ) ) {
            this.$router.push('/package');
            return;
        }
        else if( this.path == 'event' && _st.isNUE( this.reservation ) ) {
            this.$router.push('/event');
            return;
        }

        this.loadPickupLocations();
    },
    methods: {
        ...mapActions({
            clearStore: 'clearStore'
        }),
        async loadPickupLocations() {
            try {
                let api = new API();

                this.loadingLocations = true;
                let res = await api.get('/pickup-locations');
                this.loadingLocations = false;

                if( res.data.status !== true ) {
                    this.mxShowMessage( res.data.error === true ? 'Error' : 'Warning', res.data.message, res.data.error === true ? 'error' : 'warning' );
                    return;
                }

                let sj          = res.data.data.find(i => i.id == 4);
                let bayamon     = res.data.data.find(i => i.id == 6);
                let caguas      = res.data.data.find(i => i.id == 9);
                let ponce       = res.data.data.find(i => i.id == 10);
                let arecibo     = res.data.data.find(i => i.id == 11);
                let mayaguez    = res.data.data.find(i => i.id == 7);

                this.pickupLocations = [ sj, bayamon, caguas, ponce, arecibo, mayaguez ];
            }
            catch(error) {
                this.loadingLocations = false;
                this.mxShowMessage('Error', error, 'error');
            }
        },
        async processOrder() {
            try {
                let api = new API();

                this.processing = true;
                let res;

                if( this.path == 'rent' ) {
                    res = await api.post('/process-order', {
                        useDate             : this.useDate,
                        customerId          : this.customer.id,
                        packageId           : this.product.id,
                        combinationId       : this.outfit.id,
                        measurementId       : this.measurements.id,
                        pickupLocationId    : this.pickupLocationId,
                        agreement           : this.agreement,
                        outfit              : _st.extractProp( this.outfit.items, 'id' ),
                        promoCode           : this.promoCode?.code ?? '',
                    });
                }
                else {
                    res = await api.post('/process-order-event', {
                        rid                 : this.reservation.id,
                        measurementId       : this.measurements.id,
                        pickupLocationId    : this.pickupLocationId,
                        agreement           : this.agreement,
                    });
                }
                this.processing = false;

                if( res.data.status !== true ) {
                    this.mxShowMessage( res.data.error == true ? 'Error' : 'Warning', res.data.message, res.data.error == true ? 'error' : 'warning' );
                    return;
                }

                this.$router.push(`/thank-you/${res.data.data.foreignId}`);
                this.clearStore();
            }
            catch(error) {
                this.processing = false;
                this.mxShowMessage( 'Error', error, 'error' );
            }
        },
        toPhoneNum( phoneNum ) {
            return _st.toPhoneNumber( phoneNum );
        },
        toMoney(n) {
            return _st.toMoney(n);
        },
        getPackagePrice( regularPrice ) {
            if( this.promoCode == null )
                return regularPrice;

            return parseFloat( regularPrice ) - parseFloat( this.promoCode.amount );
        },
        agree() {
            this.agreement = true;
            this.contractDialog = false;
        }
    },
    components: { PackageImage }
}
</script>

<style scoped>
html,
body {
  height: 100%;
  margin: 0;
}

.box {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.box .row.header {
  flex: 0 1 auto;
  flex-flow: column;
  /* The above is shorthand for:
  flex-grow: 0,
  flex-shrink: 1,
  flex-basis: auto
  */
}

.box .row.content {
  flex: 1 1 auto;
}

.box .row.footer {
  flex: 0 1 40px;
}
</style>